import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

import { Responsive, MobileOnly, DesktopOnly } from "../../../../layout";
import { getItemName, QUESTION_RESULT_STATUS } from "../../../../util/common";

import { ConditionIcon, GalleryButton, Expandable } from "../../../../components";
import { TYRE_POSITION } from "../../../../util/tyre";

import "./index.scss";

function filterWorkingsItemsWithRemarks(workingItems) {
  return workingItems.filter(
    r =>
      !(
        (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_fixed)) ||
        r.question_status !== QUESTION_RESULT_STATUS.OK_WITH_REMARKS ||
        r.mechanic_fixed
      )
  );
}

class WorkingItems extends Component {
  render() {
    let { workingItems, showMobile, t } = this.props;
    const withRemarks = filterWorkingsItemsWithRemarks(workingItems);

    let groups = [];
    let i = -1;
    let last_group = "";

    workingItems.forEach(r => {
      if ((TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_fixed)) || r.question_status !== QUESTION_RESULT_STATUS.OK) return;

      if (r.group_name !== last_group) {
        last_group = r.group_name;
        i++;
        groups[i] = [];
      }

      groups[i].push(r);
    });

    if (withRemarks.length < 1 && groups.length < 1) return null;

    const countOk = groups.reduce((acc, group) => (acc += group.length), 0);

    return (
      <Responsive showMobile={showMobile}>
        <Grid className="working-items-container component-container">
          <Grid.Row computer={16} className="working-items-heading">
            <FontAwesomeIcon icon={faCheckCircle} className="working-items-heading-icon" />
            <h4 className="working-items-heading-title">{t("cc_checked_working_properly").message || "Checked items working properly"}</h4>
          </Grid.Row>

          {withRemarks.length > 0 && (
            <Grid className="working-items-with-remarks" verticalAlign="middle">
              <DesktopOnly>
                <Grid.Row>
                  <Grid.Column width={7} className="working-items-column-title">
                    {t("cc_item_name").message || "ITEM NAME"}
                  </Grid.Column>
                  <Grid.Column width={9} className="working-items-column-title">
                    {t("cc_remarks").message || "REMARKS"}
                  </Grid.Column>
                </Grid.Row>
              </DesktopOnly>

              {withRemarks.map((result, index) => (
                <Grid.Row key={result.question_result_id * 10 + result.tyre_position} className="working-items-row">
                  <Grid.Column computer={7} mobile={16}>
                    <DesktopOnly>
                      <ConditionIcon status={0} />
                    </DesktopOnly>
                    <span className="working-items-title">{getItemName(this.props.t, result)}</span>
                  </Grid.Column>
                  <Grid.Column computer={7} mobile={16} className="working-items-remarks">
                    {result.tyre_profile && <Expandable text={result.tyre_profile + "mm"} />}

                    <Expandable text={result.mechanic_notes} />
                  </Grid.Column>
                  <Grid.Column computer={2} mobile={16} className="working-items-images">
                    <GalleryButton images={result.images} videos={result.videos} />
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          )}

          <DesktopOnly>
            <Grid className="working-items-groups">
              <Grid.Column width={8}>
                {groups
                  .filter((g, i) => i % 2 === 0)
                  .map((group, idx) => (
                    <Grid.Row key={"evengroup_name" + idx}>
                      <h3 className="working-items-group-name-title">{group[0].group_name}</h3>
                      {group.map(result => (
                        <div className="working-items-group-name-item-box" key={"evengroup" + idx + "question" + result.question_result_id + result.tyre_position}>
                          <ConditionIcon status={0} size="small" />
                          <span className="working-items-group-title">{getItemName(this.props.t, result)}</span>
                        </div>
                      ))}
                    </Grid.Row>
                  ))}
              </Grid.Column>
              <Grid.Column width={8}>
                {groups
                  .filter((g, i) => i % 2 !== 0)
                  .map((group, idx) => (
                    <Grid.Row key={"oddgroup_name" + idx}>
                      <h3 className="working-items-group-name-title">{group[0].group_name}</h3>
                      {group.map(result => (
                        <div className="working-items-group-name-item-box" key={"oddgroup" + idx + "question" + result.question_result_id + result.tyre_position}>
                          <ConditionIcon status={0} size="small" />
                          <span className="working-items-group-title">{getItemName(this.props.t, result)}</span>
                        </div>
                      ))}
                    </Grid.Row>
                  ))}
              </Grid.Column>
            </Grid>
          </DesktopOnly>
          {countOk > 0 && (
            <MobileOnly>
              <div className="working-items-no-remarks">
                <span>
                  {countOk} {t("cc_MORE_ITEMS_CHECKED").message || "MORE ITEMS WERE CHECKED AND WITHOUT ISSUES"}
                </span>
              </div>
            </MobileOnly>
          )}
        </Grid>
      </Responsive>
    );
  }
}

const TranslatedWorkingItems = withTranslation()(WorkingItems);

TranslatedWorkingItems.filterWorkingsItems = results => results.filter(r => r.question_status < QUESTION_RESULT_STATUS.ADVISED);

TranslatedWorkingItems.filterWorkingsItemsWithRemarks = filterWorkingsItemsWithRemarks;

export default TranslatedWorkingItems;
