import axios from "axios";
import ENV from "../../util/env-config";
import APP_CONFIG from "../../util/app-config";

const requestConfig = authToken => ({ headers: { Authorization: `Bearer ${authToken}` } });

const loginRequestConfig = {
  headers: { "X-Claire-Clientplatform": APP_CONFIG.CLIENT_PLATFORM, "X-Claire-Clientversion": APP_CONFIG.FRONTEND_VERSION },
};

class Service {
  static getTokenViaGoogle(requestData) {
    return axios.post(`${ENV.authBase}/session/login/google_sign_in`, requestData, loginRequestConfig);
  }

  static getListOfReceptionists(authToken) {
    return axios.get(`${ENV.deskComBase}/counter_tablets/list_receptionists`, requestConfig(authToken));
  }

  static selectReceptionist(requestData, authToken) {
    return axios.post(`${ENV.deskComBase}/counter_tablets/register_tablet`, requestData, requestConfig(authToken));
  }

  static deskCheckinURL(requestData, authToken) {
    return axios.post(`${ENV.deskComBase}/counter_tablets/update_status`, requestData, requestConfig(authToken));
  }

  static getUserFromToken(authToken) {
    return axios.post(`${ENV.authBase}/session/me`, {}, requestConfig(authToken));
  }

  static getUserLocation(authToken, id) {
    return axios.post(`${ENV.apiBase}/locations/details`, { id }, requestConfig(authToken));
  }
}

export default Service;
