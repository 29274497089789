import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import { ConditionIcon, GalleryButton, Expandable } from "../../../../../components";
import { DesktopOnly, MobileOnly, Responsive } from "../../../../../layout";
import { getItemName, QUESTION_RESULT_STATUS } from "../../../../../util/common";
import { TYRE_POSITION } from "../../../../../util/tyre";

import "./index.scss";

function filterNotWorkedOnItemsWithRemarks(notWorkedOnItems) {
  return notWorkedOnItems.filter(
    r =>
      r.question_status === QUESTION_RESULT_STATUS.NOT_WORKED_ON &&
      ((TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_notes)) || r.mechanic_notes)
  );
}
class NotWorkedOnItems extends Component {
  render() {
    let { notWorkedOnItems, showMobile, t } = this.props;
    const withRemarks = filterNotWorkedOnItemsWithRemarks(notWorkedOnItems);

    if (notWorkedOnItems.length < 1) return null;

    const withoutRemarks = notWorkedOnItems.filter(r => !r.mechanic_notes);

    return (
      <Responsive showMobile={showMobile}>
        <Grid className="not-worked-on-items-container component-container">
          <Grid.Row computer={16} className="not-worked-on-items-heading">
            <FontAwesomeIcon icon={faTimesCircle} className="not-worked-on-items-heading-icon" />
            <h4 className="not-worked-on-items-heading-title">{t("cc_not_applied_items").message || "Not applied items"}</h4>
          </Grid.Row>

          {withRemarks.length > 0 && (
            <Grid className="not-worked-on-items-with-remarks" verticalAlign="middle">
              <DesktopOnly>
                <Grid.Row>
                  <Grid.Column width={7} className="not-worked-on-items-column-title">
                    {t("cc_item_name").message || "ITEM NAME"}
                  </Grid.Column>
                  <Grid.Column width={9} className="not-worked-on-items-column-title">
                    {t("cc_remarks").message || "REMARKS"}
                  </Grid.Column>
                </Grid.Row>
              </DesktopOnly>

              {withRemarks.map(result => (
                <Grid.Row key={result.question_result_id * 10 + result.tyre_position} className="not-worked-on-items-row">
                  <Grid.Column computer={7} mobile={16}>
                    <DesktopOnly>
                      <ConditionIcon status={4} />
                    </DesktopOnly>
                    <span className="not-worked-on-items-title">{getItemName(this.props.t, result)}</span>
                  </Grid.Column>
                  <Grid.Column computer={7} mobile={16} className="not-worked-on-items-remarks">
                    <Expandable text={result.tyre_profile + "mm"} />
                    <Expandable text={result.mechanic_notes} />
                  </Grid.Column>
                  <Grid.Column computer={2} mobile={16} className="not-worked-on-items-images">
                    <GalleryButton images={result.images} videos={result.videos} />
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          )}

          <DesktopOnly>
            <Grid className="not-worked-on-items-groups">
              <Grid.Column width={8}>
                <Grid.Row key={"evengroup_name"}>
                  {withoutRemarks.slice(0, Math.ceil(withoutRemarks.length / 2)).map(item => (
                    <div className="not-worked-on-items-group-name-item-box" key={item.id}>
                      <ConditionIcon status={4} size="small" />
                      <span className="not-worked-on-items-group-title">{getItemName(this.props.t, item)}</span>
                    </div>
                  ))}
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid.Row key={"oddgroup_name"}>
                  {withoutRemarks.slice(Math.ceil(withoutRemarks.length / 2)).map(item => (
                    <div className="not-worked-on-items-group-name-item-box" key={item.id}>
                      <ConditionIcon status={4} size="small" />
                      <span className="not-worked-on-items-group-title">{getItemName(this.props.t, item)}</span>
                    </div>
                  ))}
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </DesktopOnly>

          {withRemarks.length > 0 && (
            <MobileOnly>
              <div className="not-worked-on-items-no-remarks">
                <span>
                  {withRemarks.length} {t("cc_more_non_applied_items").message || "more non applied items"}
                </span>
              </div>
            </MobileOnly>
          )}
        </Grid>
      </Responsive>
    );
  }
}

const TranslatedNotWorkedOnItems = withTranslation()(NotWorkedOnItems);

TranslatedNotWorkedOnItems.filterNotWorkedOnItems = results => results.filter(r => r.question_result_status === QUESTION_RESULT_STATUS.NOT_WORKED_ON);

export default TranslatedNotWorkedOnItems;
