import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

import { Responsive, DesktopOnly, MobileOnly } from "../../../../../layout";
import { ANSWERS } from "../../../../../util/answers";
import { getTyreSeasonIcon, TYRE_POSITION } from "../../../../../util/tyre";

import { ConditionIcon, ActionButtons, GalleryButton, Expandable } from "../../../../../components";

import ExpandableTire from "./ExpandableTire";

import "./index.scss";

export const QUESTION_STATUS = {
  ADVISED: 2,
  CRITICAL: 3,
};

class OptionalItems extends Component {
  handleUpdateItem = (question_result_id, answer) => {
    const { readOnly, items, updateItems } = this.props;
    if (readOnly) return;

    let item = items.find(oi => oi.question_result_id === question_result_id);
    if (item) {
      item.status = answer;
      updateItems(items);
    }
  };

  handleUpdateTyreItem = (r, answer) => {
    const { readOnly, items, updateItems } = this.props;
    if (readOnly) return;

    let item = items.find(oi => oi.question_result_id === r.question_result_id && oi.tyre_position === r.tyre_position);
    if (item) {
      item.status = answer;
      updateItems(items);
    }
  };

  handleUpdateTyreReplacement = (rep, r, answer) => {
    const { readOnly, items, updateItems } = this.props;
    if (readOnly) return;

    let item = items.find(oi => oi.question_result_id === r.question_result_id && oi.tyre_position === r.tyre_position);
    if (item) {
      const { ADD_TO_CART, CONTACT_ME, NOT_OK } = ANSWERS;
      item.tyre_replacements.forEach(trr => (trr.selected = [ADD_TO_CART, CONTACT_ME, NOT_OK].includes(answer) && trr.tyre_replacement_id === rep.tyre_replacement_id));

      item.status = answer;
      updateItems(items);
    }
  };

  renderPrice = r => {
    let { include_vat, vat } = this.props.settings;
    let price = r.price;

    if (include_vat) price = price * (1 + vat / 100);

    return price > 0.01 ? <span className="optional-items-row-price">€ {price.toFixed(2)}</span> : null;
  };

  getItemClasses = item => {
    const {
      highlightMandatoryItems,
      settings: { skip_optional_items },
    } = this.props;
    let classes = "optional-items-row " + ConditionIcon.getConditionColor(item.question_status);

    if (
      highlightMandatoryItems &&
      ((item.question_status === QUESTION_STATUS.CRITICAL && item.status === ANSWERS.NOT_ANSWERED) ||
        (!skip_optional_items && item.question_status === QUESTION_STATUS.ADVISED && item.status === ANSWERS.NOT_ANSWERED))
    )
      classes += " highlight";

    return classes;
  };

  renderHeader = (icon, title, subtitle) => {
    return (
      <>
        <div className="optional-items-header-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="optional-items-header-title">
          <h4>{title}</h4>
        </div>

        <div className="optional-items-header-subtitle">
          <p>{subtitle}</p>
        </div>
      </>
    );
  };

  renderCriticalHeader = () => {
    const { t } = this.props;

    return this.renderHeader(
      faExclamationCircle,
      t("cc_critical_items").message || "Safety issues",
      t("cc_critical_items_text").message || "You must make a selection here."
    );
  };

  renderAdvisedHeader = () => {
    const {
      t,
      settings: { skip_optional_items },
    } = this.props;

    return this.renderHeader(
      faExclamationTriangle,
      t("cc_advisable_items").message || "Advisable items",
      skip_optional_items
        ? t("cc_advisable_items_text").message || "These items are optional."
        : t("cc_advisable_items_mandatory").message || "You must make a selection here"
    );
  };

  renderItem = (r, index) => {
    const { readOnly, showNotFixedButton, cartOnly } = this.props;

    return (
      <Grid className={this.getItemClasses(r)} key={index}>
        <Grid.Column computer={5} widescreen={6} mobile={16}>
          <div className="optional-items-row-title">
            <ConditionIcon className="optional-items-condition-icon" status={r.question_status} />
            <Expandable text={r.title} />
          </div>
        </Grid.Column>
        <Grid.Column computer={4} mobile={16}>
          <div className="optional-items-row-remark -margin-bottom-mobile-10">
            <Expandable text={r.mechanic_notes} />
          </div>
        </Grid.Column>
        <MobileOnly>
          <Grid.Column mobile={16} className="mobile-price-gallery">
            {this.renderPrice(r)}
            <GalleryButton images={r.images} videos={r.videos} />
          </Grid.Column>
        </MobileOnly>
        <DesktopOnly>
          <Grid.Column computer={1}>
            <div className="optional-items-row-gallery">
              <GalleryButton images={r.images} videos={r.videos} />
            </div>
          </Grid.Column>
        </DesktopOnly>
        <DesktopOnly>
          <Grid.Column computer={2}>{this.renderPrice(r)}</Grid.Column>
        </DesktopOnly>
        <Grid.Column computer={4} widescreen={3} mobile={16}>
          <ActionButtons
            status={r.status}
            showNotFixedButton={showNotFixedButton}
            onAnswer={answer => this.handleUpdateItem(r.question_result_id, answer)}
            readOnly={readOnly || r.readOnly}
            cartOnly={cartOnly}
          />
        </Grid.Column>
      </Grid>
    );
  };

  renderTyreItem = (r, index) => {
    const { readOnly, showNotFixedButton, cartOnly, t } = this.props;

    return (
      <Grid key={index} className={this.getItemClasses(r)}>
        <Grid.Column computer={5} widescreen={6} mobile={16}>
          <div className="optional-items-row-title -margin-bottom-mobile-10">
            <ExpandableTire result={r}></ExpandableTire>
          </div>
        </Grid.Column>
        <Grid.Column computer={4} mobile={16}>
          <div className="optional-items-row-remark -margin-bottom-mobile-10">
            <Expandable text={r.tyre_profile + "mm"} />
            <span>{r.mechanic_notes}</span>
          </div>
        </Grid.Column>
        {!r.tyre_replacements.length && (
          <MobileOnly>
            <Grid.Column mobile={8}>{this.renderPrice(r)}</Grid.Column>
          </MobileOnly>
        )}
        <Grid.Column computer={1} mobile={16}>
          <div className="optional-items-row-gallery">
            <GalleryButton images={r.images} videos={r.videos} />
          </div>
        </Grid.Column>
        <DesktopOnly>
          <Grid.Column computer={2}>{!r.tyre_replacements.length && this.renderPrice(r)}</Grid.Column>
        </DesktopOnly>
        <Grid.Column computer={4} widescreen={3} mobile={16}>
          {!r.tyre_replacements.length && (
            <ActionButtons
              status={r.status}
              showNotFixedButton={showNotFixedButton}
              onAnswer={answer => this.handleUpdateTyreItem(r, answer)}
              readOnly={readOnly || r.readOnly}
              cartOnly={cartOnly}
            />
          )}
        </Grid.Column>
        {r.tyre_replacements.length > 0 && (
          <Grid divided="vertically" className="optional-tyre-items-replacement-rows">
            <Grid.Row className="optional-tyre-items-replace-container">
              <DesktopOnly>
                <div className="optional-tyre-items-replace-icon-spacer"></div>
              </DesktopOnly>
              <div className="optional-tyre-items-replace-with">{t("cc_choose_replacement_tire").message || "Choose replacement tire below"}:</div>
            </Grid.Row>
            {r.tyre_replacements.map((rep, offset) => (
              <Grid.Row className="optional-tyre-items-replacement-row" key={offset}>
                <Grid.Column computer={5} widescreen={6} mobile={16} className="optional-tyre-items-replacement-column">
                  <DesktopOnly>
                    <div className="optional-tyre-items-replace-icon-spacer"></div>
                  </DesktopOnly>
                  <div className="optional-tyre-items-row-title-container">
                    <div className="optional-items-row-title optional-items-tire-title -margin-bottom-mobile-10">
                      {getTyreSeasonIcon(rep.tyre.season)}
                      <span>{rep.tyre.manufacturer}</span>
                    </div>
                    <span>{rep.tyre.description}</span>
                  </div>
                </Grid.Column>
                <Grid.Column computer={4} mobile={16} className="optional-tyre-items-row-remark-column">
                  <div className="optional-tyre-items-row-remark -margin-bottom-mobile-10">
                    <Expandable text={rep.remark} />
                  </div>
                </Grid.Column>
                <MobileOnly>
                  <Grid.Column mobile={16}>{this.renderPrice(rep)}</Grid.Column>
                </MobileOnly>
                <DesktopOnly>
                  <Grid.Column mobile={2} computer={1} />
                  <Grid.Column computer={2}>{this.renderPrice(rep)}</Grid.Column>
                </DesktopOnly>
                <Grid.Column computer={4} widescreen={3} mobile={16}>
                  <ActionButtons
                    status={rep.selected ? r.status : ANSWERS.NOT_ANSWERED}
                    showNotFixedButton={showNotFixedButton}
                    onAnswer={answer => this.handleUpdateTyreReplacement(rep, r, answer)}
                    readOnly={readOnly || r.readOnly}
                    cartOnly={cartOnly}
                  />
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        )}
      </Grid>
    );
  };

  render() {
    const { showMobile, items, question_status } = this.props;
    if (!items.length) return null;

    return (
      <Responsive showMobile={showMobile}>
        <Grid className="optional-items-container component-container">
          <Grid>
            <Grid.Row className="optional-items-header">
              {question_status === QUESTION_STATUS.CRITICAL ? this.renderCriticalHeader() : this.renderAdvisedHeader()}
            </Grid.Row>
          </Grid>
          <Grid.Row className="optional-items-rows">
            {items.map((item, index) => (TYRE_POSITION.IS_TYRE(item.tyre_position) ? this.renderTyreItem(item, index) : this.renderItem(item, index)))}
          </Grid.Row>
        </Grid>
      </Responsive>
    );
  }
}

const TranslatedOptionalItems = withTranslation()(OptionalItems);

const filterItems = (items, status) =>
  items
    .filter(r => r.question_status === status)
    .map(r => {
      if (r.status > ANSWERS.NOT_ANSWERED) r.readOnly = true;

      return r;
    });

TranslatedOptionalItems.filterAdvisedItems = items => filterItems(items, QUESTION_STATUS.ADVISED);

TranslatedOptionalItems.filterCriticalItems = items => filterItems(items, QUESTION_STATUS.CRITICAL);

TranslatedOptionalItems.QUESTION_STATUS = QUESTION_STATUS;

export default TranslatedOptionalItems;
